import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Intro from "./sections/Intro";
import Join from "./sections/Join";
import Company from "./sections/Company";
import Request from "./sections/Request";
import Event from "./sections/Event";
import Partner from "./sections/Partner";
import Head from "../Base/Head";

export default function IndustrialTech() {
  const head = {
    title: "Industrial Tech - Landmark Ventures",
    description:
      "Welcome to the Industrial Technology Practice at Landmark Ventures. We drive strategic business transformation across industrial operations, equipment, and services.",
    url: "https://industrialtech.landmarkventures.com",
    image: "https://i.imgur.com/bmEahsX.jpg",
  };

  return (
    <>
      <Head head={head} />
      <div className="industrial-tech alt">
        <Intro />
        {/* <Join /> */}
        {/* <Event /> */}
        <Request />
        <Company />
        {/* <Partner /> */}
      </div>
    </>
  );
}
