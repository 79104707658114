import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Analytics } from "@vercel/analytics/react";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Events from "./Events/Events";
import OurEvents from "./OurEvents/OurEvents";
import CES from "./CES/CES";
import IndustrialTech from "./IndustrialTech/IndustrialTech";
import IndustrialTechAlt from "./IndustrialTechAlt/IndustrialTechAlt";
import IsraelDealPortal from "./IsraelDealPortalNew/IsraelDealPortalNew";
import DealmakersIB from "./DealmakersIB/DealmakersIB";
import SisOmni from "./SisOmni/SisOmni";
import SisDelegate from "./SisDelegate/SisDelegate";
import LeadGen from "./LeadGen/LeadGen";
import LeadMagic from "./LeadMagic/LeadMagic";
import { setCookie } from "./utils";

export default function App() {
  const homepage = process.env.REACT_APP_HOMEPAGE || "";
  const [leadPage, setLeadPage] = useState(false);
  const [changeHead, setChangeHead] = useState(false);
  const [urlParams, setUrlParams] = useState(null);
  console.log("HOMEPAGE", homepage);
  console.log(`window.location.pathname`, window.location.pathname);
  console.log(`urlParams`, urlParams);

  useEffect(() => {
    try {
      // Get the current URLSearchParams object from the window.location
      const params = new URLSearchParams(window.location.search);

      // Create an empty object to store the parsed parameters
      const parsedParams = {};

      // Loop through all the URL parameters and store them in the parsedParams object
      for (const [key, value] of params.entries()) {
        parsedParams[key] = value;
      }

      // set cookies
      const userId = parsedParams["u"];
      if (userId) {
        console.log(`setting userId cookie`, userId);
        setCookie("userId", userId);
      }

      // Set the parsed parameters in the state
      setUrlParams(parsedParams);
    } catch (error) {
      console.log(`error`, error);
    }
  }, []);

  // Render the component based on the value of `HOMEPAGE` env variable
  const renderHomepage = () => {
    if (homepage === "industrialtech") {
      return <IndustrialTech />;
    } else if (homepage === "industrialtechalt") {
      return <IndustrialTechAlt />;
    } else if (homepage === "dealmakersib") {
      return <DealmakersIB />;
    } else if (homepage === "israeldealportal") {
      return <IsraelDealPortal />;
    } else if (homepage === "sisomni") {
      return <SisOmni />;
    } else if (homepage === "sisdelegate") {
      return <SisDelegate />;
    } else if (homepage === "ces") {
      return <CES />;
    } else if (homepage === "leadgen") {
      return <LeadGen />;
    } else if (homepage === "leadmagic") {
      return <LeadMagic />;
    } else if (homepage === "ourevents") {
      return <OurEvents />;
    } else {
      return <Events />;
    }
  };
  let shouldShowHeader = true;
  let shouldShowFooter = true;
  let headerProps = {
    sticky: true,
    transparent: false,
    cssClass: "position-fixed",
  };

  const isOnPage = (page) => {
    if (
      window.location.pathname.includes(page.toLowerCase()) ||
      homepage === page.toLowerCase()
    ) {
      return true;
    }
    return false;
  };
  // this checks the env variables to determine which page is being rendered
  // let shouldShowHeaderFooter =
  //   homepage !== "sisdelegate" && homepage !== "leadgen";
  // however when running locally env variables may not be set, in which case check pathname
  if (isOnPage("sisdelegate") || isOnPage("leadgen")) {
    shouldShowHeader = false;
    shouldShowFooter = false;
  }
  if (isOnPage("ces") || isOnPage("israeldealportal")) {
    shouldShowHeader = true;
    shouldShowFooter = false;
    headerProps = { transparent: true, cssClass: "ces-header", sticky: false };
  }

  return (
    <>
      <Toaster
        containerStyle={{
          top: 100,
        }}
        toastOptions={{
          style: {
            background: "#175d8c",
            color: "#fff",
          },
        }}
      />
      {shouldShowHeader && <Header headerProps={headerProps} />}
      <div className={"main " + (leadPage ? "removeP" : "")}>
        <Routes>
          <Route path="" element={renderHomepage()} />
          <Route path="events" element={<Events />} />
          <Route path="ourevents" element={<OurEvents />} />
          <Route path="ces" element={<CES />} />
          <Route path="industrialtech" element={<IndustrialTech />} />
          <Route path="industrialtechalt" element={<IndustrialTechAlt />} />
          <Route path="dealmakersib" element={<DealmakersIB />} />
          <Route path="israeldealportal" element={<IsraelDealPortal />} />
          <Route path="sisomni" element={<SisOmni />} />
          <Route path="sisdelegate" element={<SisDelegate />} />
          <Route
            path="leadgen1"
            element={
              <LeadGen
                setChangeHead={setChangeHead}
                setLeadPage={setLeadPage}
              />
            }
          />
          <Route
            path="leadgen2"
            element={
              <LeadMagic
                setChangeHead={setChangeHead}
                setLeadPage={setLeadPage}
              />
            }
          />
        </Routes>
      </div>
      <Analytics />
      {shouldShowFooter && <Footer />}
    </>
  );
}
